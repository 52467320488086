<section class="insights-top mt-160">
  <div class="wrapper-small">
    <div class="title-group">
      <h2>{{platformName}} influencer marketing</h2>
      <p class="title-krona">Audience insights</p>
    </div>
  </div>

  <div class="wrapper">
    <div class="insights-bar-top">
      <div class="insights-bar-top__group">
      </div>

      <div class="insights-bar-top__group">
      </div>
    </div>

    <div class="insights-bar" > <!-- *ngIf='insights' -->

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Quality score</span>
        <div style='display: flex; flex-direction: row'>
        <span class="insights-bar__number">{{insights.audience_quality}} / 100</span>
        <div class="information" (click)='aboutQualityScore()' matTooltip="{{'insights.iconTitle' | translate}}" [matTooltipPosition]="position.value">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
            <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
          </svg>
        </div>
        </div>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Followers</span>
        <span class="insights-bar__number">{{insights.followers}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">ER</span>
        <span class="insights-bar__number">{{insights.engagement}}%</span>
        <span class="insights-bar__line"></span>
      </div>

      <img src="assets/img/insights-bar-bg.jpg" alt="" class="insights-bar__bg">
    </div>
  </div>
</section>

<section class="audience mt-50" *ngIf='insights'>
  <div class="wrapper">
    <div class="audience__colum">
      <h3 class="insights-h3">audience quality score</h3>
      <app-gauge-chart [audience]="audience"></app-gauge-chart>
    </div>
    <div class="audience__colum">
      <h3 class="insights-h3">audience countries</h3>
      <app-map-chart [countries]='insights.all_countries'></app-map-chart>
    </div>

    <div class="audience-fullwidth">
      <h3 class="insights-h3">{{'insights.gender' | translate}}</h3>
      <app-column-chart [data]='audienceGenderAge' colorStart='#47136B' colorEnd='#4566E1'></app-column-chart>
      <p class="audience-fullwidth__description">{{'insights.distribution' | translate}}</p>
    </div>

    <div class="audience-fullwidth">
      <h3 class="insights-h3">{{'insights.countries' | translate}}</h3>
      <app-column-chart [data]='audienceCountry' colorStart='#B706F5' colorEnd='#E241DC'></app-column-chart>
      <p class="audience-fullwidth__description">{{'insights.countryFollowers' | translate}}</p>
    </div>

    <div class="audience-fullwidth">
      <h3 class="insights-h3">{{'insights.city' | translate}}</h3>
      <app-column-chart [data]='audienceCity' colorStart='#47136B' colorEnd='#4566E1'></app-column-chart>
      <p class="audience-fullwidth__description">{{'insights.cityProfiles' | translate}}</p>
    </div>

    <div class="audience-fullwidth">
      <div class="audience-fullwidth-collum">
        <div class="audience-fullwidth-collum__left">
          <h4 class="audience-fullwidth-collum__title">{{'insights.reach' | translate}}</h4>
          <p class="audience-fullwidth-collum__description">{{'insights.lastDays' | translate}}</p>
          <h3 class="audience-fullwidth-collum__number">{{insights.reach_impressions[0]}}</h3>
        </div>
        <div class="audience-fullwidth-collum__right">
          <h4 class="audience-fullwidth-collum__title">{{'insights.impressions' | translate}}</h4>
          <p class="audience-fullwidth-collum__description">{{'insights.lastDays' | translate}}</p>
          <h3 class="audience-fullwidth-collum__number">{{insights.reach_impressions[1]}}</h3>
        </div>
      </div>
      <app-horizontal-bar-chart [data]='insights.reach_impressions'></app-horizontal-bar-chart>
    </div>
  </div>
</section>
