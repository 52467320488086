import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import * as fromCampaignActions from './campaigns.actions';
import { CampaignService } from '@app/services/campaign.service';

@Injectable()
export class CampaignsEffects {
  constructor(private actions$: Actions, private campaignService: CampaignService) {}

  getCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignActions.getCampaigns),
      mergeMap(() =>
        this.campaignService.getCampaigns().pipe(
          map((campaign) => {
            return fromCampaignActions.getCampaignsSuccess({ campaign });
          })
        )
      )
    )
  );
}
