import { PostImageInfoModel } from '@app/shared/model/post-image.model';
import { SocialAccountModel } from '@app/shared/model/social-account.model';

export class PostModel {
  brandFeedback: string;
  brandName: string;
  campaignId: number;
  createdDatetime: Date;
  updatedDatetime: Date;
  id: number;
  influencerInterests: string[];
  histories?: any;
  influencerRegionCountry: string;
  postText: string;
  postDiscount: number;
  postImageInfo: PostImageInfoModel;
  postPrice: number;
  postPriceFinal: number;
  postPriceTechFee: number;
  postSocialType: string;
  seen: boolean;
  socialAccountInfo: SocialAccountModel;
  staffStatus: string;
  status: PostStatusTypes;
  user: string;
  videoUrl?: string;
  isVideo: boolean;
  selected?: boolean;
  updated?: boolean;
}

export enum PostStatusTypes {
  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
  PUBLISHED = 'published',
  WITHDRAWN = 'withdrawn',
}

export class PostFeedbackModel {
  feedbackProvided: boolean;
  campaign: number;
  id: number;
  postText: string;
  postSocialType: string;
  price: number;
  socialAccountInfo: SocialAccountModel;
  postImageUrl: string;
  videoUrl: string;
}
