import { BrandCurrencyModel } from '@app/shared/model/brand-currency.model';

export class BrandModel {
  billingCompany: string;
  brandCurrency: BrandCurrencyModel;
  ccEmail: string;
  company: string;
  createdDatetime: Date;
  funds: number;
  id: number;
  isAgency: boolean;
  iva: string;
  locationCity: string;
  locationCountry: string;
  locationPostCode: number;
  locationState: string;
  mobile?: any;
  origin?: any;
  pecUnivoco?: any;
  phone?: any;
  streetAddress: string;
  userEmail: number;
  userName: string;
  platformName?: string;
  user?: number;
}
