<div class="post-block" [ngClass]='{"post-block__unseen" : !post.seen || post.updated}'>
  <div class="post-block__wrapper">
    <div class="post-block__header">
      <img *ngIf='post.postSocialType === "instagram"' src="assets/img/icons/in-color.svg" alt="instagram post" class="post-block__icon">
      <img *ngIf='post.postSocialType === "instagramstory"' src="assets/img/icons/in-story-color.svg" alt="instagram story" class="post-block__icon">
      <img *ngIf='post.postSocialType === "facebook"' src="assets/img/icons/fb-color.svg" alt="facebook post" class="post-block__icon">
      <img *ngIf='post.postSocialType === "twitter"' src="assets/img/icons/twitter-color.svg" alt="twitter post" class="post-block__icon">
      <!-- twitter -->
      <div class="post-block__group">
        <span class="post-block__info"><b>{{post.socialAccountInfo.followers}}</b> Followers</span>
        <span class="post-block__info"><b>{{post.socialAccountInfo.engagementRate}} %</b> Engage</span>
      </div>
      <span *ngIf='showPrices' class="post-block__price">€ {{post.postPriceTechFee}}</span>
      <div *ngIf='post.updated && post.seen' class="post-block__updated"></div>
      <div *ngIf='!post.seen' class="post-block__seen"></div>
    </div>

    <div class="post-card">
      <div class="post-card__top">
        <img [src]="profileImage" alt="" class="post-card__avatar" (error)="setDefaultProfileImage()">
        <div class="post-card__gorup">
          <span class="post-card__name">{{post.socialAccountInfo.name}}</span>
          <span class="post-card__date">{{post.createdDatetime | date:'medium' }}</span>
        </div>
      </div>
      <div class="post-card__wrap-img">
        <img [src]="post.postImageInfo.imageUrl" alt="" class="post-card__middle-img">
        <img *ngIf='post.isVideo' src='assets/img/icons/icon-play.png' alt='play video' class='post-card__video-icon-img'>
      </div>
      <div class="post-card__bottom">
        <span class="post-card__description">{{post.postText}}</span>
        <div class="post-card__btn-group">
          <div class="btn-circle primary-btn-circle post-icon-group" >
            <svg *ngIf='post.status === "approved" || post.status === "published"' class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
              <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>
            </svg>
          </div>
          <div class="btn-circle tertiary-btn-circle post-icon-group" >
            <svg *ngIf='post.status === "declined"' class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
              <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="post-block__bottom" *ngIf='isAgency'>
      <span class="post-block__text">Seleziona post</span>
      <button *ngIf='!post.selected' (click)='selectPost(post, $event)' type="button" class="btn small primary-btn-radius">
        <span class="btn__text">no</span>
      </button>
      <button *ngIf='post.selected' (click)='selectPost(post, $event)' type="button" class="btn small secondary-btn-radius">
        <span class="btn__text">yes</span>
      </button>
    </div>
  </div>
</div>
