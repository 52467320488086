import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PostAdapter, PostFeedbackAdapter } from '@app/shared/adapters/post.adapter';
import { PostModel } from '@app/shared/model';
import { BrandClientsDto } from '@app/shared/dto/brand-clients.dto';
import { CONFIG } from '@config/configuration.ts';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  private postInsights: any;

  constructor(private http: HttpClient) {}

  getPosts(campaignId: number): Observable<PostModel[]> {
    const params = new HttpParams().append('campaign', `${campaignId}`);

    return this.http
      .get<any>(CONFIG.baseUrl + 'campaignposts', { params })
      .pipe(
        map((response) => {
          const postsFromApi = response.campaignPost;
          const posts = [];

          for (const post of postsFromApi) {
            posts.push(PostAdapter.createPost(post));
          }
          return posts;
        })
      );
  }

  updatePostStatus(postId: number, status, feedback?: string): Observable<any> {
    const params = new HttpParams().append('new_status', `${status}`).append('campaign_post_id', `${postId}`);
    const body = feedback ? { feedback } : {};

    return this.http
      .post<any>(CONFIG.baseUrl + 'campaignpostupdatestatus', body, { params })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPostsFeedback(postId: number, email: string): Observable<any> {
    const params = new HttpParams().append('id', `${postId}`).append('email', `${email}`);

    return this.http
      .get<any>(CONFIG.baseUrl + `abu/services`, { params })
      .pipe(
        map(({ getServicePosts }) => {
          const { result } = getServicePosts;

          const formattedPosts = result[0].post_data.flat();
          const feedbackPosts = [];

          for (const post of formattedPosts) {
            if (!Array.isArray(post)) {
              feedbackPosts.push(PostFeedbackAdapter.createPostFeedback(post));
            }
          }

          return {
            clientReviewed: result[0].client_reviewed,
            campaignName: result[0].campaign_name,
            showPrice: result[0].show_price,
            brand: result[0].brand,
            campaign: result[0].campaign,
            posts: feedbackPosts,
            id: result[0].id,
          };
        })
      );
  }

  getPostInsights(postId: number): Observable<any> {
    const params = new HttpParams().append('post_id', `${postId}`);

    return this.http
      .get<any>(CONFIG.baseUrl + `insights`, { params })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  setInsights(insights): void {
    this.postInsights = insights;
  }

  getInsights(): any {
    return this.postInsights;
  }

  contactBrand(brandClients: BrandClientsDto): Observable<any> {
    return this.http
      .post<any>(CONFIG.baseUrl + 'brandclients', { brandClients })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  makeSeenPost(postId: number): Observable<any> {
    const campaignPost = { seen: true, updated: false };
    return this.http
      .patch<any>(CONFIG.baseUrl + 'see/campaignpost/' + postId + '/', { campaignPost })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPostHistory(postId: number): Observable<any> {
    const campaignPost = { seen: true, updated: false };
    return this.http.get<any>(CONFIG.baseUrl + 'history/' + postId + '/').pipe(
      map((response) => {
        return response;
      })
    );
  }

  sendPostsFeedback(feedback): Observable<any> {
    const { id } = feedback;
    const feedbackToSend = this.serializeFeedback(feedback);
    const brandClients = { ...feedbackToSend };
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==', // TODO: refactor later, implemented the existing approach
    });
    return this.http.patch<any>(CONFIG.baseUrl + `brandclients/${id}`, { brandClients }, { headers });
  }

  private serializeFeedback(feedback): any {
    const posts = feedback.posts.map((post) => {
      return {
        feedback: post.feedback || '',
        status: post.status,
        id: post.id,
        price: post.price,
      };
    });

    return {
      client_reviewed: true,
      posts,
    };
  }
}
