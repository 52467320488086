import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, take } from 'rxjs/operators';
import { UserModel } from '@app/shared/model';
import { UserAdapter } from '@app/shared/adapters/user.adapter';
import { AuthService } from '@app/services/auth.service';
import { CONFIG } from '@config/configuration';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.baseUrl;
  loggedIn$ = new Subject<boolean>();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getUser(): Observable<UserModel> {
    return this.http.get<any>(this.baseUrl + 'users').pipe(
      map((res) => {
        const { user } = res;
        const userModel = UserAdapter.createUser(user[0]);
        this.loggedIn$.next(true);
        return userModel;
      })
    );
  }

  updateUserInfo(user: Partial<UserModel>): Observable<any> {
    const { id } = user;
    return this.http.patch<any>(this.baseUrl + `users/${id}`, { user });
  }

  createNewUser(userModel: Partial<UserModel>): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Basic bW9iaWxlQGhpdG9yaS1pbmMuY29tOmhpdG9yaTIwOSoqIQ==', // TODO: refactor later, implemented the existing approach
    });
    const userSerialized = this.serializeUser(userModel);
    return this.http.post<any>(this.baseUrl + `users`, { user: userSerialized }, { headers }).pipe(
      map((res) => {
        this.authService.login(userSerialized).pipe(take(1)).subscribe();
        const { user } = res;
        return UserAdapter.createUser(user);
      })
    );
  }

  inviteUser(userModel: Partial<UserModel>): Observable<any> {
    const userSerialized = {
      ...this.serializeUser(userModel),
      is_additional_brand_user: userModel.isAdditionalBrandUser,
    };
    return this.http
      .post<any>(this.baseUrl + `users`, { user: userSerialized })
      .pipe(
        map((response) => {
          this.sendNotification(userSerialized).subscribe();
          return response;
        })
      );
  }

  sendNotification(user: UserModel): Observable<any> {
    const { email, password } = user;
    const body = {
      loginNotification: {
        email,
        passcode: password,
      },
    };

    return this.http.post<any>(CONFIG.baseUrl + 'iol/subuser/notification', body);
  }

  private serializeUser(user: Partial<UserModel>): any {
    return {
      first_name: user.firstName,
      last_name: user.lastName,
      username: user.username,
      email: user.email,
      password: user.password,
      is_brand: user.isBrand,
      is_staff: false,
    };
  }
}
