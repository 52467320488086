<section class="insights-top mt-160">
  <div class="wrapper-small">
    <div class="title-group">
      <h2>flytrendy influencer marketing</h2>
      <p class="title-krona">Real-time analytics</p>
    </div>
  </div>

  <div class="wrapper">
    <div class="insights-bar-top">
      <div class="insights-bar-top__group">
<!--        <svg class="insights-bar-top__svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0962 16.2554L10 17.2126L8.90379 16.2554C4.98842 12.8366 3 9.81121 3 7C3 3.02141 6.10349 0 10 0C13.8965 0 17 3.02141 17 7C17 9.81121 15.0116 12.8366 11.0962 16.2554ZM3.68555 12.8034C4.09614 13.3656 4.55352 13.9373 5.05748 14.519C3.19525 14.9384 2 15.5337 2 16C2 16.807 5.57914 18 10 18C14.4209 18 18 16.807 18 16C18 15.5337 16.8047 14.9384 14.9425 14.519C15.4465 13.9373 15.9039 13.3656 16.3144 12.8034C18.5633 13.4858 20 14.5804 20 16C20 18.5068 15.5203 20 10 20C4.47973 20 0 18.5068 0 16C0 14.5804 1.43674 13.4858 3.68555 12.8034ZM10 2C12.8038 2 15 4.13816 15 7C15 9.04696 13.3727 11.5659 10 14.556C6.62733 11.5659 5 9.04696 5 7C5 4.13816 7.19624 2 10 2ZM10 4C11.6569 4 13 5.34315 13 7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7C7 5.34315 8.34315 4 10 4ZM9 7C9 6.44772 9.44771 6 10 6C10.5523 6 11 6.44772 11 7C11 7.55228 10.5523 8 10 8C9.44771 8 9 7.55228 9 7Z"/>-->
<!--        </svg>-->
<!--        <span class="insights-bar-top__text">Milano, Italy</span>-->
      </div>

      <div class="insights-bar-top__group">
<!--        <span class="insights-bar-top__text" *ngFor="let interest of campaign?.interests" [innerHTML]="interest"></span>
        <svg class="insights-bar-top__svg" width="20" height="16" viewBox="0 0 20 16">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V2.22222H16.6667V0H0ZM0 6.66668V4.44446H16.6667V6.66668H0ZM15.5556 13.3333H13.3334V11.1111H15.5556V8.88889H17.7778V11.1111H20V13.3333H17.7778V15.5556H15.5556V13.3333ZM0 15.5556V13.3333H11.1111V15.5556H0ZM0 8.88889V11.1111H11.1111V8.88889H0Z"/>
        </svg> -->
      </div>
    </div>

    <div class="insights-bar">
      <div class="insights-bar__colum">
        <span class="insights-bar__title">CPE <!-- <br>score <b>®</b> --> </span>
        <span class="insights-bar__number">{{analytics?.cpe}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Number of likes</span>
        <span class="insights-bar__number">{{analytics?.numberLikes}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Number of Comments</span>
        <span class="insights-bar__number">{{analytics?.numberComments}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Campaign Spend</span>
        <span class="insights-bar__number">€ {{analytics?.mediaSpent}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <img src="assets/img/insights-bar-bg.jpg" alt="" class="insights-bar__bg">
    </div>
  </div>
</section>

<section class="audience mt-50">
  <div class="wrapper">

    <div class="audience__colum">
      <h3 class="insights-h3">Potential reach: {{analytics?.potentialReachTotal}}</h3>
      <canvas baseChart
              [datasets]="potentialReachData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
      </canvas>
    </div>

    <div class="audience__colum">
      <h3 class="insights-h3">Number of posts</h3>
      <canvas baseChart
              [datasets]="numberOfPostsData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
      </canvas>

    </div>

    <div class="audience__colum">
    <h3 class="insights-h3">Number of likes & comments</h3>
    <canvas baseChart
            [datasets]="numberOfLikesCommentsData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType">
    </canvas>
    </div>
<!--    <div class="audience__colum">-->
<!--      <h3 class="insights-h3">FlyTrendy audience quality score</h3>-->

<!--      <div class="audience-circle">-->
<!--        <img src="assets/img/audience-circle.svg" alt="" class="audience-circle__circle">-->
<!--        <img src="assets/img/audience-circle__arrow.svg" alt="" class="audience-circle__arrow">-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="audience__colum">-->
<!--      <h3 class="insights-h3">audience country</h3>-->

<!--      <div class="audience-map"></div>-->
<!--    </div>-->
  </div>
</section>
