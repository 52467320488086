<section class="typical-page">
  <div class="wrapper">
    <h1 class="average">Authorization</h1>

    <div class="typical-page__block">
      <div class="typical-page__group max-660">
        <span class="typical-page__title">you need to log in to the system</span>
      </div>

      <a (click)='openLoginDialog()' class="btn big primary-btn-filled">
        <span class="btn__text">login</span>
      </a>

      <img src="assets/img/video-box_bg.jpg" alt="" class="typical-page__bg">
    </div>
  </div>
</section>
