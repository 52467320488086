import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PostService } from '@app/services/post.service';
import { ToastrService } from 'ngx-toastr';
import { PostReviewChangeComponent } from '@app/dialogs/post-review-change/post-review-change.component';
import { DialogService } from '@ngneat/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BrandPostDeclineComponent } from '@app/dialogs/brand-post-decline/brand-post-decline.component';
declare var jQuery: any;

@Component({
  selector: 'app-post-feedback',
  templateUrl: './post-feedback.component.html',
  styleUrls: ['./post-feedback.component.scss'],
})
export class PostFeedbackComponent implements OnInit {
  feedbackId: number;
  email: string;
  postsSubscription: Subscription;
  postFeedback: any;
  profileImage: string;
  clientReviewed = false;

  constructor(
    private route: ActivatedRoute,
    private postService: PostService,
    private toastr: ToastrService,
    private dialog: DialogService,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    document.addEventListener('DOMContentLoaded', (event) => {
      const element = this.elementRef.nativeElement.querySelector('#helpcrunch-container');
      console.log(element);
    });
  }

  ngOnInit(): void {
    this.feedbackId = +this.route.snapshot.paramMap.get('id');
    this.email = this.route.snapshot.paramMap.get('email');

    this.postsSubscription = this.postService
      .getPostsFeedback(this.feedbackId, this.email)
      .subscribe((postFeedBack) => {
        this.postFeedback = { ...postFeedBack };
        this.clientReviewed = postFeedBack.clientReviewed;
      });
  }

  sendPostChangeFeedback(post: any): void {
    const changePost = this.dialog.open(PostReviewChangeComponent, {
      data: {
        feedback: '',
      },
      closeButton: true,
    });

    changePost.afterClosed$.subscribe((feedback) => {
      if (feedback === undefined) {
        delete post.status;
        return;
      }
      post.feedback = feedback;
    });
  }

  sendPostDeclineFeedback(post: any): void {
    const changePost = this.dialog.open(BrandPostDeclineComponent, {
      data: {
        feedback: '',
      },
      closeButton: true,
    });

    changePost.afterClosed$.subscribe((feedback) => {
      if (feedback === undefined) {
        delete post.status;
        return;
      }
      post.feedback = feedback;
    });
  }

  changePostStatus(post: any, status: string): void {
    post.status = status;

    if (status === 'change') {
      this.sendPostChangeFeedback(post);
    }

    if (status === 'decline') {
      this.sendPostDeclineFeedback(post);
    }
  }

  setDefaultProfileImage(post: any): void {
    post.socialAccountInfo.profileImage = 'assets/img/icons/user-default.png';
  }

  sendFeedback(): void {
    const feedbackWarnText = this.translate.instant('feedback.warnFeedbackMsg');
    const feedbackSentSuccess = this.translate.instant('feedback.feedbackSentSuccess');
    const feedback = this.postFeedback.posts.every((post) => post.status !== undefined);
    if (!feedback) {
      this.toastr.warning(feedbackWarnText);
      return;
    }

    this.postService.sendPostsFeedback(this.postFeedback).subscribe(() => {
      this.clientReviewed = true;
      this.toastr.success(feedbackSentSuccess);
    });
  }
}
