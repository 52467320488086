<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='enableNextStep()' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>
  <form  [formGroup]="briefForm" class="new-campaign-form">
  <div class="new-campaign-brief new-campaign-form__wrapper mt-50 mb-40">
    <div class="new-campaign-form__colum">
      <div class="form__box">
        <div class="form__title-group">
          <span class="form__title">{{'newCampaign.review.contentLove' | translate }} *</span>

          <div class="information" matTooltip="{{'newCampaign.tooltips.contentLove' | translate }}" [matTooltipPosition]="position.value">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
              <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
            </svg>
          </div>
        </div>
        <textarea formControlName="contentLove" class="form__textarea"></textarea>
      </div>

      <div class="form__box">
        <div class="form__title-group">
          <span class="form__title">{{'newCampaign.review.callAction' | translate }} *</span>
          <div class="information" matTooltip="{{'newCampaign.tooltips.callAction' | translate }}" [matTooltipPosition]="position.value">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
              <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
            </svg>
          </div>
        </div>

        <input formControlName="callAction" type="text" class="form__input">
      </div>

      <div class="form__box">
        <div class="form__title-group">
          <span class="form__title">{{'newCampaign.review.moodBoard' | translate }}</span>
          <div class="information"  matTooltip="{{'newCampaign.tooltips.moodBoard' | translate }}" [matTooltipPosition]="position.value">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
              <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
            </svg>
          </div>
        </div>

        <div class="tile">
          <div class="tile__box upload small" *ngFor='let mood of moodImagesItems; let index = index;'>
            <label class="upload__add" *ngIf='!moodImages[index]?.image'>
              <svg class="upload__icons" width="70" height="70" viewBox="0 0 70 70" fill="currentColor">
                <path d="M38.4163 17.9163H31.583V31.583H17.9163V38.4163H31.583V52.083H38.4163V38.4163H52.083V31.583H38.4163V17.9163ZM34.9997 0.833008C16.1397 0.833008 0.833008 16.1397 0.833008 34.9997C0.833008 53.8597 16.1397 69.1663 34.9997 69.1663C53.8597 69.1663 69.1663 53.8597 69.1663 34.9997C69.1663 16.1397 53.8597 0.833008 34.9997 0.833008ZM34.9997 62.333C19.9322 62.333 7.66634 50.0672 7.66634 34.9997C7.66634 19.9322 19.9322 7.66634 34.9997 7.66634C50.0672 7.66634 62.333 19.9322 62.333 34.9997C62.333 50.0672 50.0672 62.333 34.9997 62.333Z"/>
              </svg>

              <input name="imageUrl" type="file" accept="image/*" (change)="fileMoodChangeEvent($event, index)" />
            </label>
            <img [src]="moodImages[index]?.image" alt="" class="upload__img">
            <div class="upload__box"  *ngIf='moodImages[index]?.image'>

              <button (click)='moodImages.splice(index, 1)' class="btn-circle tertiary-btn-circle" type="button">
                <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                  <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>
                </svg>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="new-campaign-form__colum">
      <div class="form__box">
        <span class="form__title">{{'newCampaign.review.platforms' | translate }} *</span>

        <div class="filter-soc-net__box">
          <div class="filter-soc-net__item" [ngClass]='{"active" : socialPlatforms?.platformInsta}' (click)='setSocialPlatform("platformInsta")'>
            <img src="assets/img/icons/in-color.svg" alt="Instagram Post" class="filter-soc-net__img">
          </div>
          <div class="filter-soc-net__item" [ngClass]='{"active" : socialPlatforms?.platformInstaStory}' (click)='setSocialPlatform("platformInstaStory")'>
            <img src="assets/img/icons/in-story-color.svg" alt="Instagram Story" class="filter-soc-net__img">
          </div>
          <div class="filter-soc-net__item" [ngClass]='{"active" : socialPlatforms?.platformFb}' (click)='setSocialPlatform("platformFb")'>
            <img src="assets/img/icons/fb-color.svg" alt="Facebook" class="filter-soc-net__img">
          </div>
          <div class="filter-soc-net__item" [ngClass]='{"active" : socialPlatforms?.platformTwitter}' (click)='setSocialPlatform("platformTwitter")'>
            <img src="assets/img/icons/twitter-color.svg" alt="Twitter" class="filter-soc-net__img">
          </div>
        </div>
      </div>

      <div class="form__box">
        <span class="form__title">{{'newCampaign.review.tags' | translate }} (i.e #hashtag, @brand)</span>

        <div class="custum-input icon" *ngIf='socialPlatforms?.platformInsta || socialPlatforms?.platformInstaStory'>
          <img src="assets/img/icons/in-color.svg" alt="" class="custum-input__icon">
          <input formControlName="instagram" type="text" class="form__input" placeholder='tags'>
        </div>

<!--        <div class="custum-input icon" *ngIf='socialPlatforms?.platformInstaStory'>-->
<!--          <img src="assets/img/icons/in-story-color.svg" alt="" class="custum-input__icon">-->
<!--          <input formControlName="instagram" type="text" class="form__input" placeholder='tags'>-->
<!--        </div>-->

        <div class="custum-input icon" *ngIf='socialPlatforms?.platformFb'>
          <img src="assets/img/icons/fb-color.svg" alt="" class="custum-input__icon">
          <input formControlName="facebook" type="text" class="form__input" placeholder='tags'>
        </div>

        <div class="custum-input icon" *ngIf='socialPlatforms?.platformTwitter'>
          <img src="assets/img/icons/twitter-color.svg" alt="" class="custum-input__icon">
          <input formControlName="twitter" type="text" class="form__input" placeholder='tags'>
        </div>
      </div>

      <div class="form__box">
        <div class="form__title-group">
          <span class="form__title">{{'newCampaign.review.publishGuide' | translate }}</span>
          <div class="information" matTooltip="{{'newCampaign.tooltips.do' | translate }}" [matTooltipPosition]="position.value">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
              <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
            </svg>
          </div>
        </div>

        <div class="custum-input icon button">
          <img src="assets/img/icons/check-mark.svg" alt="" class="custum-input__icon">
          <input formControlName="dosInput" type="text" class="form__input" [placeholder]="'newCampaign.review.do' | translate">
          <button (click)='addDontDos(pubGuides, briefForm.get("dosInput"))' [disabled]='!briefForm.get("dosInput").value' class="btn-circle primary-btn-circle" type="button">
            <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
              <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>
            </svg>
          </button>
        </div>
        <ol class="dont-dos">
          <li *ngFor='let item of pubGuides let index = index; trackBy: trackByIndex;' ><span>- {{item}}</span>
          <img (click)='deleteDontDos(pubGuides, index)' src="assets/img/icons/circle-cross.svg" alt="" class="custum-input__icon-delete">
          </li>
        </ol>
      </div>

      <div class="form__box">
        <div class="form__title-group" style="margin: -5px 0 5px;">
          <div class="information" matTooltip="{{'newCampaign.tooltips.dont' | translate }}" [matTooltipPosition]="position.value">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="currentColor">
              <path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z"/>
            </svg>
          </div>
        </div>

        <div class="custum-input icon button">
          <img src="assets/img/icons/circle-cross.svg" alt="" class="custum-input__icon">
          <input formControlName="dontInput" type="text" class="form__input" [placeholder]="'newCampaign.review.dont' | translate">
          <button (click)='addDontDos(dontDos, briefForm.get("dontInput"))' [disabled]='!briefForm.get("dontInput").value' class="btn-circle primary-btn-circle" type="button">
            <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
              <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>
            </svg>
          </button>
        </div>
        <ol class="dont-dos">
          <li *ngFor='let item of dontDos let index = index; trackBy: trackByIndex;'><span>- {{item}}</span>
            <img (click)='deleteDontDos(dontDos, index)' src="assets/img/icons/circle-cross.svg" alt="" class="custum-input__icon-delete">
          </li>
        </ol>
      </div>
    </div>
  </div>
  </form>
</app-campaign-wizard-step>
