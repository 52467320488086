<app-campaign-wizard-step [stepTitle]="stepTitle" [enabledNextStep]='true' (nextStepClicked)="goToNextStep()" (previousStepClicked)='goToPreviousStep()'>
<form class="new-campaign-form">
  <div class="new-campaign-review mt-50">
  <span class="new-campaign-form__main-title">{{'newCampaign.review.mainInfo' | translate }}</span>

  <div class="new-campaign-form__wrapper mb-50">
    <div class="new-campaign-review__colum">
      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">logo, {{'newCampaign.review.campaignName' | translate }}</span>

        <div class="new-campaign-review__logo-box">
          <img [src]="campaignDraft?.campaignLogoUrl ? campaignDraft?.campaignLogoUrl : campaignDraft?.campaignLogo" alt="" class="new-campaign-review__logo-img">

          <div class="new-campaign-review__logo-group">
            <span class="new-campaign-review__logo-title">{{campaignDraft?.brandCompanyName}}</span>
            <span class="new-campaign-review__logo-subtitle">{{campaignDraft?.campaignName}}</span>
          </div>
        </div>
      </div>

      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">{{'newCampaign.review.productDescription' | translate }}</span>

        <span class="new-campaign-review__text">{{campaignDraft?.campaignDescription}}</span>
      </div>

      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">{{'newCampaign.review.contentLove' | translate }}</span>

        <span class="new-campaign-review__text">{{campaignDraft?.contentLove}}</span>

      </div>

      <div class="new-campaign-review__box">
<!--        <span class="new-campaign-review__title">WHERE TO GET OUR PRODUCT</span>-->
<!--        <span class="new-campaign-review__text">{{campaignDraft?.wherePurchase}}</span>-->
      </div>
    </div>

    <div class="new-campaign-review__colum">
      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">{{'newCampaign.review.heroImage' | translate }}</span>

        <div class="tile__box big">
          <img [src]="campaignDraft?.heroImageUrl ? campaignDraft?.heroImageUrl : campaignDraft?.heroImage" alt="" class="upload__img">
        </div>
      </div>

      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">{{'newCampaign.review.tags' | translate }}</span>

        <div class="tags" *ngIf='campaignDraft?.preTags?.instagram.trim()'>
          <img src="assets/img/icons/in-color.svg" alt="" class="tags__icons">
          <span class="tags__text" *ngFor='let tag of extractTags(campaignDraft?.preTags?.instagram)'>{{tag}}</span>
        </div>

        <div class="tags" *ngIf='campaignDraft?.preTags?.facebook.trim()'>
          <img src="assets/img/icons/fb-color.svg" alt="" class="tags__icons">
          <span class="tags__text" *ngFor='let tag of extractTags(campaignDraft?.preTags?.facebook)'>{{tag}}</span>
        </div>

        <div class="tags" *ngIf='campaignDraft?.preTags?.twitter.trim()'>
          <img src="assets/img/icons/twitter-color.svg" alt="" class="tags__icons">
          <span class="tags__text" *ngFor='let tag of extractTags(campaignDraft?.preTags?.twitter)'>{{tag}}</span>
        </div>

      </div>
    </div>
  </div>

  <span class="new-campaign-form__main-title">{{'newCampaign.review.moodBoard' | translate }}</span>

  <div class="new-campaign-moodboard mb-50">

    <div *ngFor='let moodImage of campaignDraft?.moodImages || moodImages;'>
      <div class="new-campaign-moodboard__box" *ngIf='moodImage?.image'>
        <img *ngIf='moodImage?.image' [src]="moodImage?.image" alt="">
      </div>
    </div>

  </div>

  <span class="new-campaign-form__main-title">Rules and demography</span>

  <div class="new-campaign-form__wrapper">
    <div class="new-campaign-review__colum">
      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">{{'newCampaign.review.houseRules.title' | translate }}</span>

        <span class="new-campaign-review__text" *ngFor='let rule of rules' [innerHTML]='rule'></span>
      </div>

      <div class="new-campaign-review__box" *ngIf='dontDos || pubGuides'>
        <span class="new-campaign-review__title">{{'newCampaign.review.do' | translate }} / {{'newCampaign.review.dont' | translate }}</span>

        <span class="new-campaign-review__text new-campaign-review__succsess" *ngIf='pubGuides' [innerHTML]='pubGuides'></span>
        <span class="new-campaign-review__text new-campaign-review__error" *ngIf='dontDos' [innerHTML]='dontDos'></span>
      </div>
    </div>

    <div class="new-campaign-review__colum">
      <div class="new-campaign-review__box">
        <span class="new-campaign-review__title">{{'newCampaign.review.influencersWontSee' | translate }}:</span>
        <span class="new-campaign-review__text">{{'newCampaign.review.age' | translate }}: {{campaignDraft?.ageMin}}-{{campaignDraft?.ageMax}}</span>
        <span class="new-campaign-review__text">{{'newCampaign.review.gender' | translate }}: {{campaignDraft?.gender}}</span>
        <span class="new-campaign-review__text">{{'newCampaign.review.demographic' | translate }}: {{campaignDraft?.infoDemographic}}</span>
        <span class="new-campaign-review__text">{{'newCampaign.review.interestsIn' | translate }}: {{interests}}</span>
      </div>
    </div>
  </div>
</div>
</form>
</app-campaign-wizard-step>
