import { CampaignAnalyticsDto } from '@app/shared/dto/campaign-analytics.dto';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';

export class CampaignAnalyticsAdapter {
  public static createCampaignAnalytics(analyticsResponse: CampaignAnalyticsDto): CampaignAnalyticsModel {
    if (!analyticsResponse) {
      return null;
    }

    const model = new CampaignAnalyticsModel();
    model.cpe = +analyticsResponse.cpe;
    model.mediaSpent = +analyticsResponse.media_spent_including_tech_fee;
    model.numberComments = analyticsResponse.number_comments;
    model.numberLikes = analyticsResponse.number_likes;
    model.postsAccepted = analyticsResponse.number_posts_accepted;
    model.postsReceived = analyticsResponse.number_posts_received;
    model.potentialReachApproved = analyticsResponse.potential_reach_approved;
    model.potentialReachPending = analyticsResponse.potential_reach_pending;
    model.potentialReachTotal = analyticsResponse.potential_reach_total;
    return model;
  }
}
