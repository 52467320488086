import { createAction, props } from '@ngrx/store';
import { CampaignModel } from '@app/shared/model';

export enum CampaignDraftActionTypes {
  GET_CAMPAIGN_DRAFT = '[Campaign draft] Get Campaign Draft',
  GET_CAMPAIGN_DRAFT_SUCCESS = '[Campaign draft] Get Campaign Draft',

  CREATE_CAMPAIGN_DRAFT = '[Campaign draft] Create Campaign Draft',
  CREATE_CAMPAIGN_DRAFT_SUCCESS = '[Campaign draft] Get Campaign Draft Success',

  PATCH_CAMPAIGN_DRAFT = '[Campaign draft] Patch Campaign Draft Values',
  DELETE_CAMPAIGN_DRAFT = '[Campaigns draft] Delete campaign draft',

  GET_CAMPAIGN_MOOD_IMAGE = '[Campaigns draft] Get campaign Mood Image',
  ADD_CAMPAIGN_MOOD_IMAGE = '[Campaigns draft] Add campaign Mood Image',
  ADD_CAMPAIGN_MOOD_IMAGE_SUCCESS = '[Campaigns draft] Add campaign Mood Image Success',
  GET_CAMPAIGN_MOOD_IMAGE_SUCCESS = '[Campaigns draft] Get campaign Mood Image Success',

  EDIT_CAMPAIGN_DRAFT_SUBMIT = '[Campaigns draft] Edit campaign draft submit',
  EDIT_CAMPAIGN_DRAFT_SUBMIT_SUCCESS = '[Campaigns draft] Edit campaign draft submit success',
  EDIT_CAMPAIGN_DRAFT_SUBMIT_FAIL = '[Campaigns draft] Edit campaign draft submit fail',
}

export const getCampaignDraft = createAction(CampaignDraftActionTypes.GET_CAMPAIGN_DRAFT);
export const getCampaignDraftRest = createAction(CampaignDraftActionTypes.GET_CAMPAIGN_DRAFT);

export const getCampaignDraftSuccess = createAction(
  CampaignDraftActionTypes.GET_CAMPAIGN_DRAFT_SUCCESS,
  props<{ campaign: any }>()
);

export const createCampaignDraft = createAction(
  CampaignDraftActionTypes.CREATE_CAMPAIGN_DRAFT,
  props<{ campaign: CampaignModel }>()
);

export const createCampaignDraftSuccess = createAction(
  CampaignDraftActionTypes.CREATE_CAMPAIGN_DRAFT_SUCCESS,
  props<{ campaign: CampaignModel }>()
);

export const patchDraftCampaign = createAction(
  CampaignDraftActionTypes.PATCH_CAMPAIGN_DRAFT,
  props<{ campaign: Partial<CampaignModel> }>()
);

export const deleteCampaignDraft = createAction(CampaignDraftActionTypes.DELETE_CAMPAIGN_DRAFT);

export const getCampaignMoodImage = createAction(
  CampaignDraftActionTypes.GET_CAMPAIGN_MOOD_IMAGE,
  props<{ campaignId: number; imageId: number }>()
);

export const addCampaignMoodImage = createAction(
  CampaignDraftActionTypes.ADD_CAMPAIGN_MOOD_IMAGE,
  props<{ campaignId: number; image: string }>()
);

export const addCampaignMoodImageSuccess = createAction(CampaignDraftActionTypes.ADD_CAMPAIGN_MOOD_IMAGE_SUCCESS);

export const getCampaignMoodImageSuccess = createAction(
  CampaignDraftActionTypes.GET_CAMPAIGN_MOOD_IMAGE_SUCCESS,
  props<{ image: string }>()
);

export const editCampaignDraftSubmit = createAction(
  CampaignDraftActionTypes.EDIT_CAMPAIGN_DRAFT_SUBMIT,
  props<{ campaign: Partial<CampaignModel>; userEmail: string }>()
);

export const editCampaignDraftSubmitSuccess = createAction(
  CampaignDraftActionTypes.EDIT_CAMPAIGN_DRAFT_SUBMIT_SUCCESS,
  props<{ campaign: CampaignModel }>()
);

export const editCampaignDraftSubmitFail = createAction(
  CampaignDraftActionTypes.EDIT_CAMPAIGN_DRAFT_SUBMIT_FAIL,
  props<{ error: Error }>()
);
