import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  CampaignPostsState,
  makePostSeen,
  resetPostsSelection,
  selectAgency,
  selectCampaignAnalytics,
  selectCampaignOnly,
  selectCampaignPosts,
  selectUserInfo,
} from '@app/store';
import { Observable, Subject } from 'rxjs';
import { CampaignModel, PostModel, PostStatusTypes, UserModel } from '@app/shared/model';
import { AppState } from '@store/app.state';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { DialogService } from '@ngneat/dialog';
import { ContactBrandDialogComponent } from '@app/dialogs/contact-brand-dialog/contact-brand-dialog.component';
import { filter, takeUntil } from 'rxjs/operators';
import { PostOverviewDialogComponent } from '@app/dialogs/post-overview-dialog/post-overview-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagsService } from '@app/services/feature-flag.service';

@Component({
  selector: 'app-campaign-posts',
  templateUrl: './campaign-details.component.html',
})
export class CampaignDetailsComponent implements OnInit, OnDestroy {
  campaign: CampaignModel;
  campaignWallet = this.featureFlagService.isFeatureEnabled('campaignWallet');
  analytics$: Observable<CampaignAnalyticsModel>;
  destroyed$ = new Subject<boolean>();
  heroImageLoaded = false;
  isAgency = false;

  posts: PostModel[];
  postStatusModel = 'all';
  sortByModel = 'updatedDatetime';
  sortOrderDesc = false;
  noPostsInCampaignMessage = false;
  openedDialog: any;
  isBrandAccountFeature = false;

  statuses = {
    pending: 0,
    approved: 0,
    declined: 0,
    published: 0,
    updated: 0,
    all: 0,
  };

  initialSocialNetworkFilter = {
    instagram: false,
    instagramstory: false,
    facebook: false,
    twitter: false,
  };

  socialNetworkFilter: any;
  isPostsLoading: boolean;

  trackByIndex(index, item): number {
    return item.id;
  }

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private dialog: DialogService,
    private translate: TranslateService,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.socialNetworkFilter = this.initialSocialNetworkFilter;
    this.isPostsLoading = true;

    this.store.pipe(select(selectCampaignOnly), takeUntil(this.destroyed$)).subscribe((campaign) => {
      this.campaign = campaign;
    });

    this.store.pipe(select(selectAgency), takeUntil(this.destroyed$)).subscribe((isAgency) => {
      this.isAgency = isAgency;
    });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user && user.email === 'brands@flytrendy.com') {
        this.isBrandAccountFeature = true;
      }
    });

    this.store
      .pipe(
        select(selectCampaignPosts),
        takeUntil(this.destroyed$),
        filter((campaignPosts) => campaignPosts.posts !== null)
      )
      .subscribe((campaignPosts: CampaignPostsState) => {
        const { posts } = campaignPosts;

        if (Array.isArray(posts) && posts.length === 0) {
          this.noPostsInCampaignMessage = true;
        }

        this.filterPostsByStatus(this.postStatusModel);
        this.sortPostsBy();

        if (this.postStatusModel === 'all') {
          this.statuses.pending = this.numberFilteredPostsByStatus(PostStatusTypes.PENDING);
          this.statuses.approved = this.numberFilteredPostsByStatus(PostStatusTypes.APPROVED);
          this.statuses.declined = this.numberFilteredPostsByStatus(PostStatusTypes.DECLINED);
          this.statuses.published = this.numberFilteredPostsByStatus(PostStatusTypes.PUBLISHED);
          this.statuses.updated = this.numberUpdatedPosts();
          this.statuses.all = posts.length;
          this.isPostsLoading = false;
        }
      });

    this.analytics$ = this.store.pipe(select(selectCampaignAnalytics));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  postOverview(post: PostModel): void {
    this.openedDialog = this.dialog.open(PostOverviewDialogComponent, {
      data: {
        post,
        showPrices: this.campaign.showPrices,
      },
      enableClose: false,
      closeButton: true,
    });
    this.store.dispatch(makePostSeen({ postId: post.id }));
  }

  postsStatusFilterChange(event): void {
    const status = event.target.value;
    this.store.dispatch(resetPostsSelection());
    this.filterPostsByStatus(status);
  }

  numberFilteredPostsByStatus(status: string): number {
    return this.posts.filter((post) => post.status === status).length;
  }

  numberUpdatedPosts(): number {
    return this.posts.filter((post) => post.updated).length;
  }

  numberSelectedPosts(): PostModel[] {
    return this.posts?.filter((post) => post.selected === true) || [];
  }

  amountSelectedPosts(): any {
    return this.numberSelectedPosts().reduce((sum, current) => sum + current.postPriceTechFee, 0);
  }

  // tslint:disable-next-line:typedef
  async contactBrand() {
    const selectedPosts = this.numberSelectedPosts();

    const dialog = this.dialog.open(ContactBrandDialogComponent, {
      data: {
        posts: selectedPosts,
        campaignId: this.campaign.id,
        showPrices: this.campaign.showPrices,
      },
      closeButton: true,
      enableClose: false,
    });
  }

  selectedPost(post: PostModel): void {
    this.posts.filter((x) => x.id === post.id).map((x) => (x.selected = true));
  }

  sortPostsByChange(): void {
    this.store.dispatch(resetPostsSelection());
    this.sortPostsBy();
  }

  sortPostsBy(): void {
    switch (this.sortByModel) {
      case 'updatedDatetime':
        this.posts = [...this.posts].sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdDatetime) - new Date(a.createdDatetime);
        });
        break;
      case 'postPriceTechFee':
        this.posts = [...this.posts].sort((a, b) => {
          return b.postPriceTechFee - a.postPriceTechFee;
        });
        break;
      case 'followers':
        this.posts = [...this.posts].sort((a, b) => {
          return b.socialAccountInfo.followers - a.socialAccountInfo.followers;
        });
        break;
      case 'engagementRate':
        this.posts = [...this.posts].sort((a, b) => {
          return b.socialAccountInfo.engagementRate - a.socialAccountInfo.engagementRate;
        });
    }
  }

  filterPostsBySocialNetwork(socialNetwork: string): void {
    this.socialNetworkFilter = { ...this.initialSocialNetworkFilter, [socialNetwork]: true };

    this.store
      .pipe(select(selectCampaignPosts), takeUntil(this.destroyed$))
      .subscribe((campaignPosts) => {
        return (this.posts = campaignPosts.posts.filter((post) => post.postSocialType === socialNetwork));
      })
      .unsubscribe();
  }

  filterPostsByStatus(status: string): void {
    this.store
      .pipe(select(selectCampaignPosts), takeUntil(this.destroyed$))
      .subscribe((campaignPosts) => {
        if (status === 'all') {
          return (this.posts = campaignPosts.posts);
        }
        if (status === 'updated') {
          return (this.posts = campaignPosts.posts.filter((post) => post.updated));
        }
        return (this.posts = campaignPosts.posts.filter((post) => post.status === status));
      })
      .unsubscribe();
  }
}
