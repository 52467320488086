import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import OauthTokenDto from '@app/shared/dto/oauth-token.dto';
import { CONFIG } from '@config/configuration';
import { UserModel } from '@app/shared/model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl = '';

  HTTP_OPTIONS: {
    headers: HttpHeaders;
  };

  private static handleError(error: HttpErrorResponse): any {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.error_description}`);
      location.reload();
    }
    return throwError(error.error.error_description);
  }

  private static log(message: string): any {
    console.log(message);
  }

  constructor(private http: HttpClient, private tokenService: TokenService) {
    this.HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(CONFIG.clientId + ':' + CONFIG.clientSecret),
      }),
    };
  }

  get isLoggedIn(): boolean {
    return !!this.tokenService.getRefreshToken();
  }

  login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.username)
      .set('password', loginData.password)
      .set('grant_type', 'password')
      .set('client_id', CONFIG.clientId)
      .set('client_secret', CONFIG.clientSecret);

    return this.http.post<any>(CONFIG.baseUrl + 'o/token/', body, this.HTTP_OPTIONS).pipe(
      tap((res: OauthTokenDto) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
      }),
      catchError(AuthService.handleError)
    );
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams().set('refresh_token', refreshData.refresh_token).set('grant_type', 'refresh_token');
    return this.http.post<any>(CONFIG.baseUrl + 'oauth/token', body, this.HTTP_OPTIONS).pipe(
      tap((res: OauthTokenDto) => {
        this.tokenService.saveToken(res.access_token);
        this.tokenService.saveRefreshToken(res.refresh_token);
      }),
      catchError((err) => AuthService.handleError(err))
    );
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }
}
