<div *ngIf="isEditing; then postEditingDialog else postDialog"></div>
<ng-template #postDialog>
<div class="modal-post">
  <div class="modal__cont">

    <button *ngIf='feedbacksHistory.length' (click)='isEditing = true' class="btn primary-btn-filled modal__button">{{'campaignOverview.post.switchToEditingHistory' | translate}}</button>

    <div class="post-info">
      <div class="post-info__colum">
        <div class="modal-post-info">
          <img [src]='profileImage' alt="influencer profile" class="modal-post-info__avatar" (error)="setDefaultProfileImage()">
          <div class="modal-post-info__group">
            <a [href]='post.socialAccountInfo.profileUrl' target='_blank' class="modal-post-info__title">{{post.socialAccountInfo.name}}</a>
            <span class="modal-post-info__subtitle"><b>{{post.socialAccountInfo.followers}}</b> Followers</span>
          </div>

        </div>
        <div *ngIf='showPrices'>
        <div class="post-info-price__wrapper">
          <div class="post-info-price">
            <div class="post-info-price__colum">
              <span class="post-info-price__text">MEDIA BUYING</span>
              <span class="post-info-price__subtext">(TECH FEE INCL.)</span>
            </div>
            <div class="post-info-price__colum">
              <span class="post-info-price__text">€ {{post.postPriceTechFee}}</span>
            </div>
          </div>

          <div class="post-info-price">
            <div class="post-info-price__colum">
              <span class="post-info-price__text">IVA</span>
            </div>
            <div class="post-info-price__colum">
              <span class="post-info-price__text">€ {{IVA}}</span>
            </div>
          </div>
        </div>

        <div class="post-info-price-all">
          <span class="post-info-price-all__text">TOTAL</span>
          <span class="post-info-price-all__text">€ {{post.postPriceFinal}}</span>
        </div>
        </div>
        <div *ngIf="statusChangingProcess; then spinner else postStatuses"></div>

        <ng-template #spinner>
          <div class='progress-spinner'>
            <mat-spinner diameter='40'></mat-spinner>
          </div>
        </ng-template>

        <ng-template #postStatuses>
          <div class="post-info__btn-group" *ngIf='post.status === "pending"'>
            <button class="btn-circle primary-btn-circle" (click)='approvePost()' type='button'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>
              </svg>
              <span class="btn-circle__text">{{'campaignOverview.post.approve' | translate}}</span>
            </button>

            <button class="btn-circle secondary-btn-circle" type="button" (click)='changePost()'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M27 39H33V45H27V39ZM27 15H33V33H27V15ZM29.97 0C13.41 0 0 13.44 0 30C0 46.56 13.41 60 29.97 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 29.97 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>
              </svg>
              <span class="btn-circle__text">{{'campaignOverview.post.change' | translate}}</span>
            </button>

            <button class="btn-circle tertiary-btn-circle" type="button" (click)='declinePost()'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>
              </svg>

              <span class="btn-circle__text">{{'campaignOverview.post.decline' | translate}}</span>
            </button>
          </div>

          <div class="post-info__btn-group" *ngIf='post.status !== "pending"'>
            <div class="btn-circle primary-btn-circle" *ngIf='post.status === "approved" || post.status === "published"'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>
              </svg>
              <span class="btn-circle__text">{{'campaignOverview.post.approved' | translate}}</span>
            </div>

            <div class="btn-circle secondary-btn-circle" *ngIf='post.status === "declined"'>
              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">
                <path d="M27 39H33V45H27V39ZM27 15H33V33H27V15ZM29.97 0C13.41 0 0 13.44 0 30C0 46.56 13.41 60 29.97 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 29.97 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>
              </svg>
              <span class="btn-circle__text">{{'campaignOverview.post.declined' | translate}}</span>
            </div>
          </div>
        </ng-template>


        <div *ngIf="requestInsightProcess; then spinnerInsights else insightsButton"></div>
        <ng-template #spinnerInsights>
          <div class='progress-spinner insights-link'>
            <mat-spinner diameter='30'></mat-spinner>
          </div>
        </ng-template>

        <ng-template #insightsButton>
          <span (click)='openPostInsight($event, post)' class='post-block__info insights-link'>
            <img src='assets/img/icons/insights.svg' alt='insights icon' />
            <a href=''>Insights</a>
          </span>
        </ng-template>
      </div>

      <div class="post-info__colum">
        <video *ngIf='post.isVideo' controls poster="{{post.postImageInfo.imageUrl}}" autoplay>
        <source src="{{post.videoUrl}}" type="video/mp4">
        </video>

        <img *ngIf='!post.isVideo' [src]="post.postImageInfo.imageUrl" alt="" class="post-info__img">
        <p class="feedback-block__description"><b>{{post.postText}}</b></p>

        <p *ngIf='post.postSocialType === "instagram" || post.postSocialType === "instagramstory"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.instagram}}</p>
        <p *ngIf='post.postSocialType === "twitter"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.twitter}}</p>
        <p *ngIf='post.postSocialType === "facebook"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.facebook}}</p>
        <span class="post-info__submitted">Submitted: <b>{{post.createdDatetime | date: 'medium'}}</b></span>
      </div>
    </div>
  </div>
  <div (click)='ref.close()' class="ngneat-close-dialog"><svg viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg></div>

</div>
</ng-template>
<ng-template #postEditingDialog>
  <app-post-editing-dialog [feedbacksHistory]='feedbacksHistory' [editingHistory]="editingHistory" (isEditingChange)='isEditing = false'></app-post-editing-dialog>
</ng-template>
