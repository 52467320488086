import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CONFIG } from '@config/configuration.ts';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) {}

  contactUs(contactDetails: any): Observable<any> {
    const { name, email, company, subject, message } = contactDetails;
    const params = new HttpParams()
      .append('name', name)
      .append('email', email)
      .append('company', company)
      .append('subject', subject)
      .append('message', message);

    return this.http.post<any>(CONFIG.baseUrl + 'contact_us', {}, { params });
  }

  resetPassword(email: string): Observable<any> {
    const params = new HttpParams().append('email', email);
    return this.http.get<any>(CONFIG.baseUrl + 'resetpassword', { params });
  }

  requestDemo(contactPlatform: any): Observable<any> {
    return this.http.post<any>(CONFIG.baseUrl + 'contact_platform', { contactPlatform });
  }

  activateUser(email, activationToken): Observable<any> {
    const params = new HttpParams().append('email', email).append('activation_token', activationToken);
    return this.http.get<any>(CONFIG.baseUrl + 'activate', { params });
  }

  changePassword(accessToken, verification, password): Observable<any> {
    const body = new HttpParams()
      .set('verification_code', verification)
      .set('access_code', accessToken)
      .set('password', password);
    return this.http.post<any>(CONFIG.baseUrl + 'updatepassword', body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    });
  }

  getCommunityInsights(): Observable<any> {
    return this.http.get<any>(CONFIG.baseUrl + `communityinsights`);
  }

  getObjectives(): Observable<any> {
    return this.http.get<any>(CONFIG.baseUrl + `objectives`);
  }

  hidePreTags(preTags: string): string {
    if (preTags && preTags.includes(CONFIG.platform.adText)) {
      return preTags.replace(CONFIG.platform.adText, '').replace(/\s\s+/g, ' ');
    }

    return preTags;
  }

  setPreTags(preTags: string): string {
    const preDefaultTags = CONFIG.platform.adText;
    if (!preTags.includes(preDefaultTags)) {
      return (preDefaultTags + ' ' + preTags).replace(/\s\s+/g, ' ');
    }

    return preTags;
  }
}
