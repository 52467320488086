import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignWizardComponent } from '@app/campaign/campaign-wizard/campaign-wizard.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CampaignWizardComponent],
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
  exports: [CampaignWizardComponent],
})
export class CampaignWizardModule {}
