import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PostModel } from '@app/shared/model';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { postSelection, selectAgency } from '@app/store';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { PostService } from '@app/services/post.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
})
export class PostComponent implements OnInit, OnDestroy {
  profileImage: string;
  isAgency = false;
  @Input() post: PostModel;
  @Input() showPrices: boolean;
  @Output() selectedPost: EventEmitter<PostModel> = new EventEmitter();
  destroyed$ = new Subject<boolean>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private postService: PostService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.profileImage = this.post.socialAccountInfo.profilePicture;

    this.store.pipe(select(selectAgency), takeUntil(this.destroyed$)).subscribe((isAgency) => {
      this.isAgency = isAgency;
    });
  }
  selectPost(post: PostModel, event): void {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(postSelection({ postId: post.id, selected: !post.selected }));
  }
  setDefaultProfileImage(): void {
    this.profileImage = 'assets/img/icons/user-default.png';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
