import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BrandModel } from '@app/shared/model/brand.model';

export const getBrandFeatureState = createFeatureSelector<BrandModel>('brand');

export const selectBrand = createSelector(getBrandFeatureState, (state: BrandModel) => {
  return state !== null ? state : ({} as BrandModel);
});

export const selectBrandFunds = createSelector(getBrandFeatureState, (state: BrandModel) => {
  return state !== null ? state.funds : 0;
});

export const selectAgency = createSelector(getBrandFeatureState, (state: BrandModel) => {
  return state !== null ? state.isAgency : false;
});
