import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, concatMap, switchMap, withLatestFrom, catchError } from 'rxjs/operators';
import * as fromCampaignDraftActions from './campaign-draft.actions';
import { ActionType, select, Store } from '@ngrx/store';
import { selectCampaignOnly } from '@app/store/campaign-overview';
import { AppState } from '@store/app.state';
import { CampaignModel } from '@app/shared/model';
import * as fromCampaignOverviewActions from '@store/campaign-overview/campaign-overview.actions';
import { selectCampaignDraft } from '@app/store/campaign-draft/campaign-draft.selectors';
import { CampaignService } from '@app/services/campaign.service';
import { emptyCampaignDraft } from '@app/shared/model/campaign-draft';
import { of } from 'rxjs';

@Injectable()
export class CampaignDraftEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private campaignService: CampaignService) {}

  setCampaignDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignOverviewActions.getSingleCampaignSuccess),
      withLatestFrom(this.store.pipe(select(selectCampaignOnly))),
      map(([action, campaign]: [ActionType<any>, CampaignModel]) => {
        return fromCampaignDraftActions.createCampaignDraft({ campaign });
      })
    )
  );

  getCampaignDraft$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCampaignDraftActions.getCampaignDraft),
      withLatestFrom(this.store.pipe(select(selectCampaignDraft)), this.store.pipe(select(selectCampaignOnly))),
      map(([action, campaignOverview]: [ActionType<any>, CampaignModel, CampaignModel]) => {
        const campaignDraft = campaignOverview ? campaignOverview : emptyCampaignDraft;
        return fromCampaignDraftActions.createCampaignDraft({ campaign: campaignDraft });
      })
    );
  });

  getCampaignMoodImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignDraftActions.getCampaignMoodImage),
      concatMap(({ campaignId, imageId }) =>
        this.campaignService
          .getCampaignMoodImages(campaignId, imageId)
          .pipe(
            map((response) =>
              fromCampaignDraftActions.getCampaignMoodImageSuccess({ image: response.getMoodImageWithId.mood_image })
            )
          )
      )
    )
  );

  addCampaignMoodImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignDraftActions.addCampaignMoodImage),
      concatMap(({ campaignId, image }) =>
        this.campaignService
          .addCampaignMoodImage(campaignId, image)
          .pipe(map((response) => fromCampaignDraftActions.addCampaignMoodImageSuccess()))
      )
    )
  );

  editCampaignDraftSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignDraftActions.editCampaignDraftSubmit),
      switchMap(({ campaign, userEmail }) =>
        this.campaignService.submitCampaignDraft(campaign, userEmail).pipe(
          // tslint:disable-next-line:no-shadowed-variable
          map((campaign) => fromCampaignDraftActions.editCampaignDraftSubmitSuccess({ campaign })),
          catchError(({ error }) => of(fromCampaignDraftActions.editCampaignDraftSubmitFail({ error })))
        )
      )
    )
  );
}
