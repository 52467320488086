<section class='profile mb-100 mt-100' xmlns='http://www.w3.org/1999/html'>
  <div class="profile__header wrapper-small">
    <h2>{{'topBar.myProfile' | translate}}</h2>
    <a routerLink='/campaigns' class="btn-text campagin-primary-btn-text">
      <svg class="btn-text__svg-back" width="8" height="15" viewBox="0 0 8 15" fill="currentColor">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.23562 7.42855L8 13.6506L6.88219 14.8571L4.76366e-07 7.42855L6.88219 -2.29912e-05L8 1.20653L2.23562 7.42855Z"/>
      </svg>
      <span class="btn-text__text" style="text-transform: uppercase;">Back to dashboard</span>
    </a>
  </div>

  <div class="wrapper">
    <div class="profile-block-bar">

      <div class="profile-block">

        <div class="profile-block__top">
          <span class="profile-block__line"></span>
          <span class="profile-block__main-title">{{'myProfile.personalDetails' | translate}}</span>

          <button type="button" class="btn-text tertiary-btn-text" *ngIf='!editPersonalInfo' (click)='editPersonalInfo = !editPersonalInfo'>
            <svg class="btn-text__svg-left" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
              <path d="M0 15.5556V17.7778H6.66667V15.5556H0ZM0 2.22222V4.44444H11.1111V2.22222H0ZM11.1111 20V17.7778H20V15.5556H11.1111V13.3333H8.88889V20H11.1111ZM4.44444 6.66667V8.88889H0V11.1111H4.44444V13.3333H6.66667V6.66667H4.44444ZM20 11.1111V8.88889H8.88889V11.1111H20ZM13.3333 6.66667H15.5556V4.44444H20V2.22222H15.5556V0H13.3333V6.66667Z"/>
            </svg>
            <span class="btn-text__text">Edit information</span>
          </button>

          <button (click)='cancelEditInfoChanges()' type="button" class="btn-text tertiary-btn-text" *ngIf='editPersonalInfo'>
            <span class="btn-text__text">Cancel</span>
          </button>

          <button (click)='saveBrandInfo()' [disabled]='!personalDataForm.valid' type="button" class="btn-text tertiary-btn-text" style='margin-left: 30px' *ngIf='editPersonalInfo'>
            <span class="btn-text__text">Save</span>
          </button>

        </div>
        <!-- remove the "no-edit" class from the block to edit -->
        <div class="profile-block__middle" [ngClass]='{"no-edit" : !editPersonalInfo}'>
          <form [formGroup]='personalDataForm' class='profile-block-form'>
          <div class="profile-block__colum">
            <div class="form__box">
              <span class="form__title">{{'myProfile.firstName' | translate}}</span>
                <input formControlName="firstName" type="text" class="form__input">
                <span class='form__error' *ngIf="!personalDataForm.get('firstName').valid && personalDataForm.get('firstName').touched">Please enter first name</span>

            </div>

            <div class="form__box">
              <span class="form__title">Company Name</span>
                <input formControlName="company" type="text" class="form__input">
            </div>

            <button (click)='changePassword()' type="button" class="btn-text tertiary-btn-text">
              <span class="btn-text__text">{{'changePassword.changePasswordTitle' | translate }}</span>
            </button>
          </div>

          <div class="profile-block__colum">
            <div class="form__box">
              <span class="form__title">{{'myProfile.lastName' | translate}}</span>
                <input formControlName="lastName" type="text" class="form__input">
                <span class='form__error' *ngIf="!personalDataForm.get('lastName').valid && personalDataForm.get('lastName').touched">Please enter last name</span>
            </div>
            <div class="form__box" title='you can not edit email'>
              <span class="form__title">Email</span>
                <input formControlName="email" type="text" class="form__input" style='opacity: 0.5'>
            </div>
          </div>

          <div class="profile-block__colum">
            <div class="form__box">
              <span class="form__title">{{'myProfile.phoneNumber' | translate}}</span>
                <input formControlName="phone" type="text" class="form__input">
            </div>
            <div class="form__box">
              <span class="form__title">{{'myProfile.mobileNumber' | translate}}</span>
                <input formControlName="mobile" type="text" class="form__input">
            </div>
          </div>
          </form>
        </div>

      </div>

      <img src="assets/img/profile-block-bar__bg.jpg" alt="" class="profile-block-bar__bg">

    </div>

    <div class="profile-block">
      <form [formGroup]='billingDataForm' class='profile-block-form'>
      <div class="profile-block__top">
        <span class="profile-block__line"></span>
        <span class="profile-block__main-title">{{'myProfile.billingDetails' | translate}}</span>

        <button *ngIf='!editBillingInfo' (click)='editBillingInfo = !editBillingInfo' type="button" class="btn-text tertiary-btn-text">
          <svg class="btn-text__svg-left" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
            <path d="M0 15.5556V17.7778H6.66667V15.5556H0ZM0 2.22222V4.44444H11.1111V2.22222H0ZM11.1111 20V17.7778H20V15.5556H11.1111V13.3333H8.88889V20H11.1111ZM4.44444 6.66667V8.88889H0V11.1111H4.44444V13.3333H6.66667V6.66667H4.44444ZM20 11.1111V8.88889H8.88889V11.1111H20ZM13.3333 6.66667H15.5556V4.44444H20V2.22222H15.5556V0H13.3333V6.66667Z"/>
          </svg>
          <span class="btn-text__text">Edit information</span>
        </button>


        <button (click)='cancelEditInfoChanges()' type="button" class="btn-text tertiary-btn-text" *ngIf='editBillingInfo'>
          <span class="btn-text__text">Cancel</span>
        </button>

        <button (click)='saveBrandInfo()' [disabled]='!billingDataForm.valid' type="button" class="btn-text tertiary-btn-text" style='margin-left: 30px' *ngIf='editBillingInfo'>
          <span class="btn-text__text">Save</span>
        </button>

      </div>
      <!-- remove the "no-edit" class from the block to edit -->
      <div class="profile-block__middle" [ngClass]='{"no-edit" : !editBillingInfo}'>
        <div class="profile-block__colum">
          <div class="form__box">
            <span class="form__title">Billing Company</span>
            <input formControlName="billingCompany"  type="text" class="form__input">
          </div>

          <div class="form__box">
            <span class="form__title">Street Address or PO Box</span>
            <input formControlName="streetAddress" type="text" class="form__input">
          </div>
        </div>

        <div class="profile-block__colum">
          <div class="form__box">
            <span class="form__title">Country</span>
            <input formControlName="locationCountry"  type="text" class="form__input">
          </div>
          <div class="form__box">
            <span class="form__title">City</span>
            <input formControlName="locationCity" type="text" class="form__input">
          </div>
        </div>

        <div class="profile-block__colum">
          <div class="form__box">
            <span class="form__title">State</span>
            <input formControlName="locationState" type="text" class="form__input">
          </div>
          <div class="form__box">
            <span class="form__title">Postcode / ZIP</span>
            <input formControlName="locationPostCode" type="text" class="form__input">
          </div>
        </div>
      </div>
      </form>
    </div>

    <div class="profile-block">
      <form [formGroup]='taxDataForm' class='profile-block-form'>
      <div class="profile-block__top">
        <span class="profile-block__line"></span>
        <span class="profile-block__main-title" style='text-transform: capitalize'>{{'myProfile.taxNumber' | translate}}</span>

      </div>
      <!-- remove the "no-edit" class from the block to edit -->
      <div class="profile-block__middle" [ngClass]='{"no-edit" : !editBillingInfo}'>
        <div class="profile-block__colum">
          <div class="form__box">
            <span class="form__title">Tax Number</span>
            <input formControlName="iva" type="number" class="form__input">
          </div>
        </div>
      </div>
      </form>
    </div>

    <div class="profile-block">
      <form [formGroup]='ccEmailForm' class='profile-block-form'>
      <div class="profile-block__top">
        <span class="profile-block__line"></span>
        <span class="profile-block__main-title">{{'myProfile.ccEmail' | translate}}</span>

      </div>
      <!-- remove the "no-edit" class from the block to edit -->
      <div class="profile-block__middle" [ngClass]='{"no-edit" : !editBillingInfo}'>
        <div class="profile-block__colum">
          <div class="form__box">
            <span class="form__title">Email campaign</span>
            <input formControlName="ccEmail" type="text" class="form__input">
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</section>
