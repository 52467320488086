<footer>
  <div class="footer wrapper">
    <div class="footer__row">
    </div>

    <div class="footer__row">
      <div class="footer__colum">
      </div>
      <div class="footer__colum">
        <span class="footer__text">© 2020 ItaliaOnline – Direzione e coordinamento di Libero Acquisition S.á r.l. - P. IVA 03970540963</span>
      </div>
    </div>
  </div>
</footer>
