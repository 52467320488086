import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfluencersStepComponent } from '@app/campaign/influencers-step/influencers-step.component';
import { CampaignWizardModule } from '@app/campaign/campaign-wizard/campaign-wizard.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InfluencersStepComponent],
  imports: [CommonModule, CampaignWizardModule, NgxSliderModule, TranslateModule],
  exports: [InfluencersStepComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InfluencersStepModule {}
