import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampaignAdapter } from '@app/shared/adapters/campaign.adapter';
import { CampaignModel } from '@app/shared/model';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { CampaignAnalyticsAdapter } from '@app/shared/adapters/campaign-analytics.adapter';
import { CampaignDto } from '@app/shared/dto';
import { CommonService } from '@app/services/common.service';
import * as moment from 'moment';
import { CONFIG } from '@config/configuration.ts';
import { FeatureFlagsService } from '@app/services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private featureFlagService: FeatureFlagsService
  ) {}

  getCampaigns(): Observable<CampaignModel[]> {
    const params = new HttpParams().append('platform_names', CONFIG.platformName);

    return this.http
      .get<any>(CONFIG.baseUrl + 'campaigns', { params })
      .pipe(
        map((response) => {
          const campaignsFromApi = response.campaign;
          const campaigns = [];

          for (const campaign of campaignsFromApi) {
            campaign.pre_tags = this.hidePreTags(campaign.pre_tags);
            campaigns.push(CampaignAdapter.createCampaign(campaign));
          }
          return campaigns;
        })
      );
  }

  getCampaignFromApi(id: number): Observable<CampaignModel> {
    return this.http.get<any>(CONFIG.baseUrl + `campaigns/${id}`).pipe(
      map((response) => {
        const { campaign } = response;
        campaign.pre_tags = this.hidePreTags(campaign.pre_tags);
        return CampaignAdapter.createCampaign(campaign);
      })
    );
  }

  getCampaignAnalytics(id: number): Observable<CampaignAnalyticsModel> {
    const params = new HttpParams().append('id', id + '');
    return this.http
      .get<any>(CONFIG.baseUrl + `analytics`, { params })
      .pipe(
        map((response) => {
          const { campaignAnalytics } = response;
          return CampaignAnalyticsAdapter.createCampaignAnalytics(campaignAnalytics);
        })
      );
  }

  getCampaignMoodImages(campaignId: number, imageId: number): Observable<any> {
    const params = new HttpParams().append('campaign_id', campaignId + '').append('mood_image_id', imageId + '');
    return this.http.get<any>(CONFIG.baseUrl + `getmoodimagewithid`, { params });
  }

  getCampaignWalletTypes(): Observable<any> {
    const params = new HttpParams().append('platform_name', CONFIG.platformName);
    return this.http.get<any>(CONFIG.baseUrl + `campaign_wallet_types`, { params });
  }

  addCampaignMoodImage(campaignId: any, image: string): Observable<any> {
    const params = { campaign_id: campaignId, mood_image: image };
    return this.http.post<any>(CONFIG.baseUrl + `addmoodimage`, params);
  }

  deleteCampaignMoodImage(campaignId: number): Observable<any> {
    if (!campaignId) {
      return of(true);
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        campaign_id: campaignId,
      },
    };

    return this.http.delete<any>(CONFIG.baseUrl + `deletecampaignmoodimage`, options);
  }

  submitCampaignDraft(campaign: Partial<CampaignModel>, userEmail: string): Observable<any> {
    const serializedCampaign = this.campaignSerializer(campaign, userEmail);
    let url = 'campaigns';
    let requestType = 'put';
    console.log('submit campaign ');
    if (!campaign.id) {
      requestType = 'post';
    } else {
      url = `campaigns/${campaign.id}`;
    }
    return this.http[requestType]<any>(CONFIG.baseUrl + url, { campaign: serializedCampaign }).pipe(
      map((response) => {
        // @ts-ignore
        const { campaign } = response;
        return CampaignAdapter.createCampaign(campaign);
      })
    );
  }

  private campaignSerializer(campaign: Partial<CampaignModel>, userEmail: string): CampaignDto {
    const serializedCampaign = {} as CampaignDto;

    serializedCampaign.ageMax = campaign.ageMax;
    serializedCampaign.ageMin = campaign.ageMin;
    serializedCampaign.brand_comp_name = campaign.brandCompanyName;
    serializedCampaign.call_action = campaign.callAction;
    serializedCampaign.campaign_link = campaign.campaignLink;
    serializedCampaign.content_love = campaign.contentLove;
    serializedCampaign.dont_dos = campaign.dontDos;
    serializedCampaign.gender = campaign.gender;
    serializedCampaign.info_demographic = campaign.infoDemographic;
    serializedCampaign.interests = campaign.interests;
    serializedCampaign.open_until_date = moment(campaign.openUntilDate).format('YYYY-MM-DDThh:mm:ss');
    serializedCampaign.platform_fb = campaign.socialPlatforms.platformFb;
    serializedCampaign.platform_insta = campaign.socialPlatforms.platformInsta;
    serializedCampaign.platform_insta_story = campaign.socialPlatforms.platformInstaStory;
    serializedCampaign.platform_twitter = campaign.socialPlatforms.platformTwitter;
    serializedCampaign.pre_tags = this.setPreTags(campaign.preTags);
    serializedCampaign.pro_comp_name = campaign.campaignName;
    serializedCampaign.pro_desc = campaign.campaignDescription;
    serializedCampaign.pub_guides = campaign.pubGuides;
    serializedCampaign.reviewed = false;
    serializedCampaign.user = userEmail;

    if (campaign.publishBriefApp) {
      serializedCampaign.publish_brief_app = moment(campaign.publishBriefApp).format('YYYY-MM-DDThh:mm:ss');
    } else {
      serializedCampaign.publish_brief_app = new Date().toISOString();
    }

    if (campaign.heroImage) {
      serializedCampaign.hero_image = campaign.heroImage;
    }

    if (this.featureFlagService.isFeatureEnabled('campaignWallet') && campaign.campaignWalletTypeId) {
      serializedCampaign.campaign_wallet_type_id = campaign.campaignWalletTypeId;
    }

    if (campaign.campaignLogo) {
      serializedCampaign.campaign_logo = campaign.campaignLogo;
    }

    if (campaign.objective) {
      serializedCampaign.objective = campaign.objective;
    }

    if (campaign.campaignBudget) {
      serializedCampaign.campaign_budget = campaign.campaignBudget;
    }

    return serializedCampaign;
  }

  private setPreTags(tags: any): any {
    return {
      facebook: this.commonService.setPreTags(tags.facebook),
      instagram: this.commonService.setPreTags(tags.instagram),
      twitter: this.commonService.setPreTags(tags.twitter),
    };
  }

  private hidePreTags(tags: any): any {
    const filteredTags = {} as any;

    if (tags.facebook) {
      filteredTags.facebook = this.commonService.hidePreTags(tags.facebook);
    }

    if (tags.instagram) {
      filteredTags.instagram = this.commonService.hidePreTags(tags.instagram);
    }

    if (tags.twitter) {
      filteredTags.twitter = this.commonService.hidePreTags(tags.twitter);
    }

    return filteredTags;
  }
}
