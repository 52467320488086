import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectCampaignAnalytics, selectCampaignOnly } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '@store/app.state';
import { Subject } from 'rxjs';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { CampaignModel } from '@app/shared/model';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  analytics: CampaignAnalyticsModel;
  campaign: CampaignModel;

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['Real time analytics'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public potentialReachData: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];

  public numberOfPostsData: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];

  public numberOfLikesCommentsData: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.pipe(select(selectCampaignAnalytics), takeUntil(this.destroyed$)).subscribe((analytics) => {
      this.analytics = analytics;

      if (analytics) {
        this.potentialReachData = [
          { data: [this.analytics.potentialReachApproved], label: 'Posts approved' },
          { data: [this.analytics.potentialReachPending], label: 'Posts in review' },
        ];

        this.numberOfPostsData = [
          { data: [this.analytics.postsReceived], label: 'Number of posts received' },
          { data: [this.analytics.postsAccepted], label: 'Number of posts in review' },
        ];
        this.numberOfLikesCommentsData = [
          { data: [this.analytics.numberLikes], label: 'Number of likes' },
          { data: [this.analytics.numberComments], label: 'Number of comments' },
        ];
      }
    });

    this.store.pipe(select(selectCampaignOnly), takeUntil(this.destroyed$)).subscribe((campaign) => {
      this.campaign = campaign;
    });
  }
}
