import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectBrand } from '@app/store';
import { Observable } from 'rxjs';
import { BrandModel } from '@app/shared/model';
import { TokenService } from '@app/services/token.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as fromBrandActions from '@app/store';
import { TranslateService } from '@ngx-translate/core';
declare let paypal: any;
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-paypal-dialog',
  templateUrl: './paypal-dialog.component.html',
  styleUrls: ['./paypal-dialog.component.scss'],
})
export class PaypalDialogComponent implements OnInit, AfterViewInit {
  brand$: Observable<BrandModel>;
  accessToken: string;

  paymentForm = this.fb.group(
    {
      amount: ['', [Validators.required, Validators.min(50)]],
    },
    {
      updateOn: 'change',
    }
  );

  private readonly payPayPalFee = 0.039;
  private readonly payPayPalFlatFee = 0.35;

  constructor(
    private fb: FormBuilder,
    public ref: DialogRef,
    private store: Store<AppState>,
    private tokenService: TokenService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.brand$ = this.store.pipe(select(selectBrand));
    // tslint:disable-next-line:no-string-literal
    if (!window['paypal']) {
      this.loadExternalScript('https://www.paypalobjects.com/api/checkout.js').then(() => {
        this.initPayPalButton();
      });
    } else {
      this.initPayPalButton();
    }
    this.accessToken = this.tokenService.getToken();
    this.paymentForm.patchValue({ amount: 50 }, { emitEvent: false });
  }

  initPayPalButton(): void {
    paypal.Button.render(
      {
        env: 'production',
        commit: true,
        style: {
          size: 'large',
        },
        payment: () => {
          // tslint:disable-next-line:no-shadowed-variable
          return paypal.request.post(this.getPaymentUrl()).then((data: any) => {
            const { createPaypalPayment } = data;
            if (createPaypalPayment && createPaypalPayment.id) {
              return createPaypalPayment.id;
            }
          });
        },
        onAuthorize: (data) => {
          return (
            paypal.request
              .post(this.getExecutePaymentUrl(), {
                paymentID: data.paymentID,
                payerID: data.payerID,
              })
              // tslint:disable-next-line:no-shadowed-variable
              .then((data) => {
                if (data && data.executePaypalPayment && data.executePaypalPayment.success) {
                  this.updateFundsSuccess();
                } else {
                  this.updateFundsFail();
                }
              })
          );
        },
      },
      '#paypal-button'
    );
  }
  ngAfterViewInit(): void {
    setTimeout(() => {}, 500);
  }

  private loadExternalScript(scriptUrl: string): any {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  private updateFundsSuccess(): void {
    const successMsg = this.translate.instant('paypalWallet.fundsSuccessMsg');
    const addAmount = this.paymentForm.get('amount').value;
    this.store.dispatch(fromBrandActions.updateBrandFunds({ addAmount }));
    this.toastr.success(successMsg);
    this.ref.close();
  }

  private updateFundsFail(): void {
    const failMsg = this.translate.instant('commonMsg.somethingWentWrong');
    this.toastr.error(failMsg);
  }

  getFeeAmount(): number {
    const amount = 1; // this.paymentForm.get('amount').value;
    const calculatedFee = amount * this.payPayPalFee;
    return +(calculatedFee + this.payPayPalFlatFee).toFixed(2) || 0;
  }

  private getPaymentUrl(): string {
    const amount = this.paymentForm.get('amount').value;
    return (
      CONFIG.baseUrl +
      'createpaypalpayment?amount=' +
      amount +
      '&access_token=' +
      this.accessToken +
      '&charges=' +
      this.getFeeAmount()
    );
  }

  private getExecutePaymentUrl(): string {
    return CONFIG.baseUrl + 'executepaypalpayment?access_token=' + this.accessToken;
  }
}
