import BrandDto from '@app/shared/dto/brand.dto';
import { BrandModel } from '@app/shared/model/brand.model';
import { BrandCurrencyModel } from '@app/shared/model/brand-currency.model';

export class BrandAdapter {
  public static createBrand(brandResponse: BrandDto): BrandModel {
    if (!brandResponse) {
      return null;
    }

    const brandCurrency = this.createBrandCurrency(brandResponse);
    const model = new BrandModel();

    model.brandCurrency = brandCurrency;
    model.billingCompany = brandResponse.billing_company;
    model.ccEmail = brandResponse.cc_email;
    model.company = brandResponse.company;
    model.createdDatetime = brandResponse.created_datetime;
    model.funds = brandResponse.funds;
    model.id = brandResponse.id;
    model.isAgency = brandResponse.is_agency;
    model.locationCity = brandResponse.location_city;
    model.locationCountry = brandResponse.location_country;
    model.locationPostCode = brandResponse.location_postcode;
    model.locationState = brandResponse.location_state;
    model.streetAddress = brandResponse.street_address;
    model.userEmail = brandResponse.user;
    model.userName = brandResponse.username;
    model.mobile = brandResponse.mobile;
    model.origin = brandResponse.origin;
    model.pecUnivoco = brandResponse.pec_univoco;
    model.phone = brandResponse.phone;
    model.iva = brandResponse.iva;
    return model;
  }

  private static createBrandCurrency(brandResponse: BrandDto): BrandCurrencyModel {
    const brandCurrency = brandResponse.brand_currency;

    return {
      ballparkFigures: brandCurrency.ballpark_figures,
      code: brandCurrency.code,
      name: brandCurrency.name,
      symbol: brandCurrency.symbol,
    };
  }
}
