import { environment } from '@env/environment';

export const CONFIG = Object({
  baseUrl: environment.baseUrl,
  S3_IMAGES: environment.S3_IMAGES,
  API_HOST: 'https://api.flytrendy.com',
  API_PREFIX: 'api',
  // S3_IMAGES: 'https://images.flytrendy.com',
  clientId: 'VbcstftHcdti1R7GzNoSeteAWmqphM597jVgdeSj',
  clientSecret:
    'J3yBXqCUCCyKUwWjMc8umJRLDLCPwjGblRiPxQvLT5Xa8Z9HgB3utr0jwsQfsrWEPkGy1ANcJaFaxAW5ejOOyTgCXNuOdziK17xJj6HRnSq7hKR8c2MPs1ZqhDARWJ9T',
  platformPrefix: 'ft',
  platformName: 'io_platform',
  isAgency: false,
  platform: {
    techFees: 0.25,
    iva: 0.22,
    adText: '#adv',
    defaultLanguage: 'it',
    name: 'Italiaonline',
    languages: {
      it: {
        name: 'Italian',
        nativeName: 'Italiano',
      },
    },
    communityDemographics: {
      gender: [{ male: 35 }, { female: 65 }],
      influencer_total_followers: { facebook: 10.44, twitter: 0, instagram: 89.56 },
      interests: {
        Photography: 12,
        'Sports, Fitness & Wellbeing': 14,
        'Education & Technology': 7,
        'Online Shopping & Entertainment': 11,
        'Music & Movies': 13,
        'Books, Magazines & Games': 7,
        'Food, Lifestyle & Events': 11,
        'Social & Family': 10,
        'Travel & Cars': 13,
        Animals: 2,
      },
      age_categories: { from_18_to_30: 59, from_31_to_40: 29, '40+': 12 },
      regions: {
        Veneto: 5.33,
        Liguria: 1.9,
        Calabria: 4.13,
        'Emilia-Romagna': 6.48,
        Lombardia: 18.3,
        "Valle d'Aosta": 0.75,
        Marche: 2,
        'Trentino-Alto Adige': 0.97,
        Sicilia: 9.95,
        Campania: 13.7,
        Puglia: 6.4,
        Basilicata: 1.33,
        Abruzzo: 3.22,
        'Friuli-Venezia Giulia': 1.42,
        Piemonte: 6.97,
        Toscana: 3.77,
        Lazio: 9.35,
        Umbria: 1.24,
        Molise: 0.66,
        Sardegna: 2.13,
      },
    },
    countries: [
      {
        country_code: 'IT',
        country_name: 'Italy',
        dialling_code: 39,
        country_locale: 'country_italy',
        index: 13,
      },
    ],
    brand_name_new_campaign: false,
    dos: ['Non devi inserire tag e collaborazione con FlyTrendy su Instagram per questa campagna'],
    donts: [],
    contactus: {
      defaultSubject: '',
    },
    campaign: {
      campaign_wallet: true,
    },
    socialLinks: {
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
    },
  },
});
