import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectCampaignDraft } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { CampaignModel } from '@app/shared/model';
import { Subject } from 'rxjs';
import * as campaignDraftActions from '@store/campaign-draft';
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-influencers-step',
  templateUrl: './influencers-step.component.html',
  styleUrls: ['./influencers-step.component.scss'],
})
export class InfluencersStepComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  stepTitle = 'influencers';

  options: Options = {
    floor: 16,
    ceil: 80,
  };

  age = {
    ageMin: 16,
    ageMax: 80,
  };

  gender = {
    male: false,
    female: false,
  };

  targetRegion: any[] = [];
  interests: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.targetRegion = CONFIG.platform.countries;

    this.store
      .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
      .subscribe((campaignDraft: CampaignModel) => {
        if (campaignDraft) {
          this.interests = [...campaignDraft.interests];
          this.setGender(campaignDraft.gender);
          this.setDemographic(campaignDraft.infoDemographic);
          this.setAge(campaignDraft.ageMax, campaignDraft.ageMin);
        }
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setGender(gender: string): void {
    if (gender === 'all') {
      this.gender.male = true;
      this.gender.female = true;
    } else {
      this.gender[gender] = true;
    }
  }

  setAge(ageMax: number, ageMin): void {
    this.age = {
      ageMin,
      ageMax,
    };
  }

  setDemographic(demographic: string): void {
    if (!demographic.length) {
      return;
    }
    const countries = demographic.split(',');
    countries.forEach((country) => {
      this.targetRegion.map((region) => {
        if (region.country_name === country) {
          region.active = true;
        }
      });
    });
  }

  checkInterest(interest: string): boolean {
    return this.interests.includes(interest);
  }

  toggleInterests(interest: string): void {
    const index = this.interests.indexOf(interest);
    if (this.interests.includes(interest)) {
      this.interests.splice(index, 1);
    } else {
      this.interests.push(interest);
    }
  }

  enableNextStep(): boolean {
    return (
      !!this.interests.length &&
      (this.gender.male || this.gender.female) &&
      this.targetRegion.filter((e) => e.active === true).length > 0
    );
  }

  changeGender(gender: string): void {
    this.gender[gender] = !this.gender[gender];
  }
  goToNextStep(): void {
    this.saveData();
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }
  saveData(): void {
    let gender;
    const { ageMax, ageMin } = this.age;
    const { interests } = this;
    const demographic = [];
    let infoDemographic = '';

    this.targetRegion.forEach((region) => {
      if (region.active === true) {
        demographic.push(region.country_name);
      }
    });

    infoDemographic = demographic.join(',');

    if (this.gender.female && this.gender.male) {
      gender = 'all';
    } else {
      gender = this.gender.female ? 'female' : 'male';
    }

    const campaign = {
      gender,
      ageMax,
      ageMin,
      interests,
      infoDemographic,
    } as Partial<CampaignModel>;

    this.store.dispatch(campaignDraftActions.patchDraftCampaign({ campaign }));
  }

  goToPreviousStep(): void {
    this.saveData();
    this.router.navigate(['../brief'], { relativeTo: this.activatedRoute });
  }
}
