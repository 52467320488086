import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaypalDialogComponent } from './paypal-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PaypalDialogComponent],
  imports: [CommonModule, BrowserModule, ReactiveFormsModule],
})
export class PaypalDialogModule {}
