import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'flytrendy.com', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom-left',
  palette: {
    popup: {
      background: '#47136B',
    },
    button: {
      background: '#41E2BA',
      text: '#fff',
    },
  },
  content: {
    message:
      'Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella cookie policy.\n' +
      'Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa, proseguendo la navigazione di questa pagina, interagendo con un link o un pulsante al di fuori di questa informativa o continuando a navigare in altro modo.',
    dismiss: 'Accetta',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: 'https://flytrendy.com/cookie-policy',
    policy: 'Cookie Policy',
  },
  theme: 'block',
  type: 'info',
};
