export class CampaignAnalyticsModel {
  cpe: number;
  mediaSpent: number;
  numberComments: number;
  numberLikes: number;
  postsAccepted: number;
  postsReceived: number;
  potentialReachApproved: number;
  potentialReachPending: number;
  potentialReachTotal: number;
}
