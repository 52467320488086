import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { BrandModel, PostModel } from '@app/shared/model';
import { ContactBrandPost } from '@app/shared/dto/brand-clients.dto';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { contactBrand, resetPostsSelection, selectBrand } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as campaignOverviewActions from '@app/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FeatureFlagsService } from '@app/services/feature-flag.service';

@Component({
  selector: 'app-contact-brand-dialog',
  templateUrl: './contact-brand-dialog.component.html',
  styleUrls: ['./contact-brand-dialog.component.scss'],
})
export class ContactBrandDialogComponent implements OnInit, OnDestroy {
  posts: PostModel[];
  postPrices: ContactBrandPost[] = [];
  brandEmail = '';
  brandEmail2 = '';
  brandEmail3 = '';
  destroyed$ = new Subject<boolean>();
  brand: BrandModel;
  campaignId: number;
  showPrice = false;
  isPriceShown = true;

  constructor(
    public ref: DialogRef,
    private store: Store<AppState>,
    private translate: TranslateService,
    private actions: Actions,
    private toastr: ToastrService,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    // Feature-flag:showPrices
    const isShowPricesFeatureEnabled = this.featureFlagService.isFeatureEnabled('showPrices');
    const postsToSend = [];
    const { data } = this.ref;
    this.campaignId = data.campaignId;
    this.isPriceShown = data.showPrices;

    if (!isShowPricesFeatureEnabled) {
      this.isPriceShown = false;
    }
    const posts = Object.values(data.posts) as PostModel[];
    for (const post of posts) {
      postsToSend.push({ id: post.id, price: +(post.postPriceTechFee * 2).toFixed(2) });
    }

    this.posts = posts;
    this.postPrices = postsToSend;

    this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand) => {
      this.brand = brand;
    });
    this.contactBrandSuccess();
    this.contactBrandFail();
  }

  contactBrandSuccess(): void {
    this.actions.pipe(ofType(campaignOverviewActions.contactBrandSuccess), takeUntil(this.destroyed$)).subscribe(() => {
      const contactBrandSuccess = this.translate.instant('campaignOverview.contactBrand.contactBrandSuccess');

      this.ref.close();
      this.toastr.success(contactBrandSuccess);
    });
  }

  contactBrandFail(): void {
    this.actions
      .pipe(ofType(campaignOverviewActions.contactBrandFail), takeUntil(this.destroyed$))
      .subscribe(({ error }) => {
        // TODO: Improve error handling
        const contactBrandFail = this.translate.instant('campaignOverview.contactBrand.contactBrandFail');
        this.toastr.error(contactBrandFail);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.store.dispatch(resetPostsSelection());
  }

  getTotalCost(): number {
    return +this.postPrices.reduce((a, b) => a + (b.price || 0), 0).toFixed(2);
  }
  trackByIndex(index: number, obj: PostModel): number {
    return index;
  }
  contactBrand(): void {
    const brandEmails = [this.brandEmail];

    if (this.brandEmail2) {
      brandEmails.push(this.brandEmail2);
    }

    if (this.brandEmail3) {
      brandEmails.push(this.brandEmail3);
    }

    const brandClients = {
      brand: this.brand.id,
      campaign: this.campaignId,
      client_emails: brandEmails,
      is_agency: this.brand.isAgency,
      posts: this.postPrices,
      show_price: this.showPrice,
    };
    this.store.dispatch(contactBrand({ brandClients }));
  }
}
